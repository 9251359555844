<template>
  <nav class="navbar navbar-expand-lg navbar-absolute"
       :class="{'bg-dark': showMenu, 'navbar-transparent': !showMenu}">
    <div class="container-fluid">
      <div v-if="currentUser" class="navbar-nav ml-auto">
        <div class="navbar-wrapper">
          <div class="navbar-toggle d-inline" :class="{toggled: $sidebar.showSidebar}">
            <button type="button"
                    class="navbar-toggler"
                    aria-label="Navbar toggle button"
                    @click="toggleSidebar">
              <span class="navbar-toggler-bar bar1"></span>
              <span class="navbar-toggler-bar bar2"></span>
              <span class="navbar-toggler-bar bar3"></span>
            </button>
          </div>
            <a class="navbar-brand">{{routeName}}</a>
        </div>
      </div>
      <button class="navbar-toggler " type="button"
              @click="toggleMenu"
              data-toggle="collapse"
              data-target="#navigation"
              aria-controls="navigation-index"
              aria-label="Toggle navigation">
        <span class="navbar-toggler-bar navbar-kebab"></span>
        <span class="navbar-toggler-bar navbar-kebab"></span>
        <span class="navbar-toggler-bar navbar-kebab"></span>
      </button>

      <collapse-transition>
        <div class="collapse navbar-collapse show" v-show="showMenu">
          <ul class="navbar-nav" :class="$rtl.isRTL ? 'mr-auto' : 'ml-auto'">
            <div v-if="currentUser" class="navbar-nav ml-auto">
              <div class="search-bar input-group" @click="searchModalVisible = true">
                <!-- <input type="text" class="form-control" placeholder="Search...">
                <div class="input-group-addon"><i class="tim-icons icon-zoom-split"></i></div> -->

                <button class="btn btn-link" id="search-button" data-toggle="modal" data-target="#searchModal">
                  <i class="tim-icons icon-zoom-split"></i>
                </button>
                <!-- You can choose types of search input -->
              </div>
              <modal :show.sync="searchModalVisible"
                    class="modal-search"
                    id="searchModal"
                    :centered="false"
                    :show-close="true">
                <input slot="header" v-model="searchQuery" type="text" class="form-control" id="inlineFormInputGroup" placeholder="SEARCH">
              </modal>
              <base-dropdown tag="li"
                            :menu-on-right="!$rtl.isRTL"
                            title-tag="a" class="nav-item">
                <a slot="title" href="#" class="dropdown-toggle nav-link" data-toggle="dropdown" aria-expanded="true">
                  <div class="notification d-none d-lg-block d-xl-block"></div>
                  <i class="tim-icons icon-sound-wave"></i>
                  <p class="d-lg-none">
                    New Notifications
                  </p>
                </a>
                <li class="nav-link">
                  <a href="#" class="nav-item dropdown-item">Mike John responded to your email</a>
                </li>
                <li class="nav-link">
                  <a href="#" class="nav-item dropdown-item">You have 5 more tasks</a>
                </li>
                <li class="nav-link">
                  <a href="#" class="nav-item dropdown-item">Your friend Michael is in town</a>
                </li>
                <li class="nav-link">
                  <a href="#" class="nav-item dropdown-item">Another notification</a>
                </li>
                <li class="nav-link">
                  <a href="#" class="nav-item dropdown-item">Another one</a>
                </li>
              </base-dropdown>
            </div>

            <div v-if="!currentUser" class="navbar-nav ml-auto">
                <li class="nav-link">
                  <router-link to="/register" class="nav-item dropdown-item">
                    <font-awesome-icon icon="user-plus" /> Register
                  </router-link>
                </li>
                <div class="dropdown-divider"></div>
                <li class="nav-link">
                  <router-link to="/login" class="nav-item dropdown-item">
                    <font-awesome-icon icon="sign-in-alt" /> Login
                  </router-link>
                </li>
            </div>
            <div v-if="currentUser" class="navbar-nav ml-auto">
                <li class="nav-link">
                  <router-link to="/profile" class="nav-item dropdown-item">
                    <font-awesome-icon icon="user" />
                    {{ currentUser.username }}
                  </router-link>
                </li>
                <div class="dropdown-divider"></div>
                <li class="nav-link">
                  <a class="nav-item dropdown-item" href @click.prevent="logOut">
                    <font-awesome-icon icon="sign-out-alt" />LogOut
                  </a>
                </li>
            </div>
          </ul>
        </div>
      </collapse-transition>
    </div>
  </nav>
</template>
<script>
  import { CollapseTransition } from 'vue2-transitions';
  import Modal from '@/components/Modal';

  export default {
    components: {
      CollapseTransition,
      Modal
    },
    computed: {
      routeName() {
        const { name } = this.$route;
        return this.capitalizeFirstLetter(name);
      },
      currentUser() {
      return this.$store.state.auth.user;
      },
      isRTL() {
        return this.$rtl.isRTL;
      }
    },
    data() {
      return {
        activeNotifications: false,
        showMenu: false,
        searchModalVisible: false,
        searchQuery: ''
      };
    },
    methods: {
      capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      },
      toggleNotificationDropDown() {
        this.activeNotifications = !this.activeNotifications;
      },
      closeDropDown() {
        this.activeNotifications = false;
      },
      toggleSidebar() {
        this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
      },
      hideSidebar() {
        this.$sidebar.displaySidebar(false);
      },
      toggleMenu() {
        this.showMenu = !this.showMenu;
      },
      logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/login');
      }
    }
  };
</script>
<style>
</style>
