<template>
  <div class="wrapper">
  <div v-if="currentUser" class="navbar-nav ml-auto">
    <side-bar>
      <template slot="links">
        <sidebar-link to="/kpi" :name="$t('sidebar.kpi')" icon="tim-icons icon-chart-pie-36"/>
        <sidebar-link to="/gantt" :name="$t('sidebar.gantt')" icon="tim-icons icon-align-left-2"/>
        <!-- <sidebar-link to="/notifications" :name="$t('sidebar.notifications')" icon="tim-icons icon-bell-55"/>
        <sidebar-link to="/profile" :name="$t('sidebar.userProfile')" icon="tim-icons icon-single-02"/>
        <sidebar-link to="/table-list" :name="$t('sidebar.tableList')" icon="tim-icons icon-puzzle-10"/> -->
      </template>
    </side-bar>
  </div>
 
    <div class="main-panel">
      <top-navbar></top-navbar>

      <dashboard-content @click.native="toggleSidebar">

      </dashboard-content>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<style lang="scss">
</style>
<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "./MobileMenu";
export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu
  },
  computed: {
    currentUser() {
    return this.$store.state.auth.user;
    }
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    }
  }
};
</script>
